// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuOption_option__S7MvI {\n    margin-top: 10px;\n}\n\n.MenuOption_item__KClJH {\n    padding-top: 7px;\n}\n\n.MenuOption_item__KClJH:hover {\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/Menu/MenuOption.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".option {\n    margin-top: 10px;\n}\n\n.item {\n    padding-top: 7px;\n}\n\n.item:hover {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": "MenuOption_option__S7MvI",
	"item": "MenuOption_item__KClJH"
};
export default ___CSS_LOADER_EXPORT___;

export const content = [
    {
        title: "post_1_title",
        subtitle: "post_1_subtitle",
        text: "post_1_text",
        backgroundImage: "",
        sideImage: "",
        side: "",
    },
    {
        title: "post_2_title",
        subtitle: "post_2_subtitle",
        text: "post_2_text",
        backgroundImage: "1670_0018.jpg",
        sideImage: "red.jpg",
        side: "left",
    },
    {
        title: "post_3_title",
        subtitle: "post_3_subtitle",
        text: "post_3_text",
        backgroundImage: "",
        sideImage: "Garlic-1.jpg",
        side: "left",
    },
    {
        title: "post_4_title",
        subtitle: "post_4_subtitle",
        text: "post_4_text",
        backgroundImage: "1 murado.jpg",
        sideImage: "1 spring violet.jpg",
        side: "right",
    },
    {
        title: "post_5_title",
        subtitle: "post_5_subtitle",
        text: "post_5_text",
        backgroundImage: "Garlic-1.jpg",
        sideImage: "1 spring white.jpg",
        side: "right",
    },
    {
        title: "post_6_title",
        subtitle: "post_6_subtitle",
        text: "post_6_text",
        backgroundImage: "Garlic-1.jpg",
        sideImage: "1 murado.jpg",
        side: "right",
    },
    {
        title: "post_7_title",
        subtitle: "post_7_subtitle",
        text: "post_7_text",
        backgroundImage: "",
        sideImage: "",
        side: "middle",
    },
    {
        title: "post_8_title",
        subtitle: "post_8_subtitle",
        text: "post_8_text",
        backgroundImage: "",
        sideImage: "",
        side: "middle",
    },
    {
        title: "post_9_title",
        subtitle: "post_9_subtitle",
        text: "post_9_text",
        backgroundImage: "",
        sideImage: "",
        side: "middle",
    },
    {
        title: "post_10_title",
        subtitle: "post_10_subtitle",
        text: "post_10_text",
        backgroundImage: "",
        sideImage: "vadilica 1.jpg",
        side: "left",
    }
]

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "i, i:hover {\n    color: #efefef;\n    font-size: 18px;\n}\n\n.FooterContactItem_footer_contact_item__qXg-p {\n    margin-bottom: 8px;\n}\n\n.FooterContactItem_display_inline__iHpv2 {\n    display: inline;\n    horiz-align: center;\n    margin-left: 5px;\n}", "",{"version":3,"sources":["webpack://./src/components/Footer/FooterContactItem.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":["i, i:hover {\n    color: #efefef;\n    font-size: 18px;\n}\n\n.footer_contact_item {\n    margin-bottom: 8px;\n}\n\n.display_inline {\n    display: inline;\n    horiz-align: center;\n    margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer_contact_item": "FooterContactItem_footer_contact_item__qXg-p",
	"display_inline": "FooterContactItem_display_inline__iHpv2"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmblaCarousel_embla__whYCg {\n    margin: auto;\n    --slide-height: 200px;\n    --slide-spacing: 1rem;\n    --slide-size: 100%;\n    height: 400px;\n    overflow: hidden;\n}\n\n.EmblaCarousel_embla__viewport__NoAeq {\n    overflow: hidden;\n}\n\n.EmblaCarousel_embla__container__N2OCB {\n    display: flex;\n    touch-action: pan-y pinch-zoom;\n    margin-left: calc(var(--slide-spacing) * -1);\n}\n\n.EmblaCarousel_embla__slide__hg\\+Fb {\n    padding-left: var(--slide-spacing);\n    overflow: hidden;\n    object-fit: cover;\n    width: 100%;\n    flex-shrink:0;\n}\n\n.EmblaCarousel_embla__image__nei09 {\n    height: 100%;\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/Content/EmblaCarousel.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qBAAqB;IACrB,qBAAqB;IACrB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,4CAA4C;AAChD;;AAEA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":[".embla {\n    margin: auto;\n    --slide-height: 200px;\n    --slide-spacing: 1rem;\n    --slide-size: 100%;\n    height: 400px;\n    overflow: hidden;\n}\n\n.embla__viewport {\n    overflow: hidden;\n}\n\n.embla__container {\n    display: flex;\n    touch-action: pan-y pinch-zoom;\n    margin-left: calc(var(--slide-spacing) * -1);\n}\n\n.embla__slide {\n    padding-left: var(--slide-spacing);\n    overflow: hidden;\n    object-fit: cover;\n    width: 100%;\n    flex-shrink:0;\n}\n\n.embla__image {\n    height: 100%;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"embla": "EmblaCarousel_embla__whYCg",
	"embla__viewport": "EmblaCarousel_embla__viewport__NoAeq",
	"embla__container": "EmblaCarousel_embla__container__N2OCB",
	"embla__slide": "EmblaCarousel_embla__slide__hg+Fb",
	"embla__image": "EmblaCarousel_embla__image__nei09"
};
export default ___CSS_LOADER_EXPORT___;

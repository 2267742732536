// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Content_content__GUt2b {\n    margin-left: 0px; /* Same as the width of the sidebar */\n}\n\n@media screen and (min-width: 768px) {\n    .Content_content__GUt2b {\n        margin-left: 290px; /* Adjusted margin for desktop devices */\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/Content/Content.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,qCAAqC;AAC3D;;AAEA;IACI;QACI,kBAAkB,EAAE,wCAAwC;IAChE;AACJ","sourcesContent":[".content {\n    margin-left: 0px; /* Same as the width of the sidebar */\n}\n\n@media screen and (min-width: 768px) {\n    .content {\n        margin-left: 290px; /* Adjusted margin for desktop devices */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Content_content__GUt2b"
};
export default ___CSS_LOADER_EXPORT___;

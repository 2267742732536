// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderMenuOption_item__ok7em {\n    font-family: 'Playfair Display', sans-serif;\n    font-size: 13px;\n    line-height: 22px;\n    font-style: normal;\n    font-weight: 400;\n    text-transform: uppercase;\n    letter-spacing: 2px;\n    color: #2b2622;\n}\n\n.HeaderMenuOption_item__ok7em hr {\n    border-top: 1px solid #c9b7aa;\n}\n\n.HeaderMenuOption_item__ok7em:last-child{border:none !important;}", "",{"version":3,"sources":["webpack://./src/components/Header/HeaderMenuOption.module.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;IAC3C,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,6BAA6B;AACjC;;AAEA,yCAAiB,sBAAsB,CAAC","sourcesContent":[".item {\n    font-family: 'Playfair Display', sans-serif;\n    font-size: 13px;\n    line-height: 22px;\n    font-style: normal;\n    font-weight: 400;\n    text-transform: uppercase;\n    letter-spacing: 2px;\n    color: #2b2622;\n}\n\n.item hr {\n    border-top: 1px solid #c9b7aa;\n}\n\n.item:last-child{border:none !important;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "HeaderMenuOption_item__ok7em"
};
export default ___CSS_LOADER_EXPORT___;
